import React from 'react';
import styled from 'styled-components';
import { DEFAULT_MARGIN } from '@components/layout/Grid/Grid.constants';

export const ScrollTopContainer = styled.div<{isBannerExist}>`
  width: 42px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 0.7px solid #FFFFFF;
  border-radius: 50%;
  backdrop-filter: blur(4px);
  box-shadow: -2.8px 5.6px 14px 1.4px rgba(0, 0, 0, 0.12);
  position: sticky;
  margin-right: 15px;
  bottom: ${props => props.isBannerExist ? 80 : 35}px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  cursor: pointer;
  transition: bottom 0.5s ease-in-out;
  will-change: bottom;
  ${props => props.theme.breakpoints.up('sm')} {
    position: fixed;
    right: ${DEFAULT_MARGIN.md}px
  }
`;
