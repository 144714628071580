import React from 'react';
import { useTranslation } from 'next-i18next';
import { LanguageCode } from 'src/constants';
import { ProductCardsWithBrandQuery } from 'src/generated/graphql';
import InfinityScrollLoader from '@components/InfinityScrollLoader';
import { Card } from '@components/common/CardGrid';
import useExchange from '@hooks/useExchange';
import { PATH_PRODUCT } from 'src/routes/paths';
import { discountAmount } from '@lib/price';
import Grid from '@components/layout/Grid/Grid';
import { CardGroupContainer } from '@components/common/CardGroupGrid/styled';
import ScrollTop from '@components/ScrollTop/ScrollTop';
import { ICardClickProps } from '../Card/type';
import ProductsSkeleton from './GridProductsSkeleton';

type Props = {
  products: ProductCardsWithBrandQuery['productCards'];
  filterCount: number;
  isLoading: boolean;
  isVirtual?: boolean;
  handleCardClick?: (props: ICardClickProps, order: number) => void;
  nextOffset?: () => void | null;
};

export default function Products({ products, filterCount, isLoading, handleCardClick, nextOffset = null }: Props) {
  const { i18n } = useTranslation();
  const { isReady } = useExchange(i18n.language as LanguageCode);
  /* eslint-disable @typescript-eslint/no-unused-vars */

  const convertProductInfo = (productInfo) => {
    const item = { ...productInfo };
    item.subImageUrl = productInfo.mainImageUrl;
    item.imageUrl = productInfo.subImageUrl;
    item.isGoods = true;
    item.brandName = productInfo.brand.name;
    item.brandCd = productInfo.brand.code;
    item.goodsNo = productInfo.id.toString();
    item.languageCode = i18n.language as LanguageCode;
    item.productViewUrl = PATH_PRODUCT.view(item.goodsNo);
    item.price = productInfo.salePrice;
    item.discountPercentage = discountAmount(productInfo.price, productInfo.salePrice);
    item.badgeName = productInfo.badgeNames[0] || '';
    item.isPromotion = Boolean(productInfo?.promotionEndDate);

    return item;
  };

  const handleScroll = () => {
    if (filterCount !== products.length) nextOffset();
  };

  if (!isReady) return <div></div>;

  return (
    <>
      <CardGroupContainer siblings={<ScrollTop />}>
        {(products || []).map((goodsInfo, idx) => {
          const cardType = convertProductInfo(goodsInfo);
          return (
            <Grid.Item key={idx} xl={3} lg={6} md={4} sm={4}>
              <Card
                {...cardType}
                onClick={(product) => {
                  handleCardClick(product, idx + 1);
                }}
              />
            </Grid.Item>
          );
        })}
        {isLoading && <ProductsSkeleton idx={-1}/>}
      </CardGroupContainer>

      {nextOffset && !isLoading && products && products.length > 0 && filterCount !== products.length && (
        <div style={{ position: 'relative', top: '-350px' }}>
          <InfinityScrollLoader action={handleScroll} />
        </div>
      )}

    </>
  );
}
